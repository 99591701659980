
<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="pets"
			:loading="loading"
			:footer-props="footerProps"
			:options.sync="options"
	 		sort-by="updated"
			sort-desc
			:server-items-length="total"
		>
			<template v-slot:item.image ="{ item }">
				<v-img
					:src="item.imageUrl"
					width="50"
					contain
		 		></v-img>
				<div v-html="item.title"></div>
			</template>
			<template v-slot:item.createdAt="{ item }">
				{{ (new Date(item.createdAt)).toLocaleString() }} 
			</template>
			<template v-slot:item.by="{ item }">
				<div class="username-row">
					{{ item.by.globalName }}
					<span class="discord-id">{{ item.by.username }}</span>
					<span class="discord-id">{{ item.by.discordId }}</span>
				</div>
			</template>
			<template v-slot:item.action="{ item }">
				<v-btn color="primary" text @click="view(item)">
					<AdminPetsApproveModal :pet="item" />
				</v-btn>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import AdminPetsApproveModal from '@/views/admin/components/AdminPetsApproveModal';

export default {
	name: 'AdminPetsApproveTable',
	components: { AdminPetsApproveModal },
	props: {
		loading: {
			type: Boolean,
			required: true
		},
		pets: {
			type: Array,
			required: true
		},
		total: {
			type: Number,
			required: true
		}
	},
	data: () => ({
		options: {
			page: 1,
			itemsPerPage: 25,
		},
		footerProps: {
			'items-per-page-options': [25, 50, 100],
		},
		headers: [
			{ text: '', value: 'image', sortable: false },
			{ text: 'Pet Name', value: 'name', sortable: true },
			{ text: 'By', value: 'by', sortable: true },
			{ text: 'Created', value: 'createdAt', sortable: true },
			{ text: '', value: 'action', sortable: false },
		]
	}),
	watch: {
		options: {
			handler() {
				this.fetchPets();
			},
			deep: true,
		},
	},
	methods: {
		view (pet) {
			// TODO DISPLAY AND APPROVE
			console.log(pet);
		},
		fetchPets() {
			this.$emit('fetch', { page: this.options.page, limit: this.options.itemsPerPage });
		}
	}
};
</script>

<style scoped>

.discord-id {
	opacity: 0.6;
	font-size: xx-small;

}

.username-row {
	display: flex;
	flex-direction: column;
}

</style>
